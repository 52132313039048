import React, { useState } from "react"

// Components
import SidebarLayout from "../../../layouts/SidebarLayout"
import Section from "../../../components/section/Section"
import Seo from "../../../components/seo"
import ContentNavigation from "../../../components/scrollspy"
import ogImage from "../../../images/seo/accordions/accordion.png"
// Libraries / Components for Preview
import PreviewBlock from "../../../components/preview"

// Preview
import AccordionHeroComponent from "../../../library/components/accordion/react/_preview/AccordionHeroComponent"
import AccordionBasicPreview from "../../../library/components/accordion/react/_preview/basic"
import AccordionWithIconPreview from "../../../library/components/accordion/react/_preview/icon"
import AccordionElevatedPreview from "../../../library/components/accordion/react/_preview/elevated"
import ElevatedIconAccordionPreview from "../../../library/components/accordion/react/_preview/elevated-icon"
import OutlineAccordionPreview from "../../../library/components/accordion/react/_preview/outline"
import OutlineAccordionWithIconPreview from "../../../library/components/accordion/react/_preview/outline-icon"

// All
const AccordionBasicJsx = require("!!raw-loader!../../../library/components/accordion/react/basic.jsx")
const AccordionBasicHTML = require("!!raw-loader!../../../library/components/accordion/html/basic.html")

const AccordionWithIconsJsx = require("!!raw-loader!../../../library/components/accordion/react/icon.jsx")
const AccordionWithIconsHTML = require("!!raw-loader!../../../library/components/accordion/html/icon.html")

const AccordionElevatedJsx = require("!!raw-loader!../../../library/components/accordion/react/elevated.jsx")
const AccordionElevatedHTML = require("!!raw-loader!../../../library/components/accordion/html/elevated.html")

const OutlineAccordionJsx = require("!!raw-loader!../../../library/components/accordion/react/outline.jsx")
const OutlineAccordionHTML = require("!!raw-loader!../../../library/components/accordion/html/outline.html")

const OutlineAccordionWithIconsJsx = require("!!raw-loader!../../../library/components/accordion/react/outline-icon.jsx")
const OutlineAccordionWithIconsHTML = require("!!raw-loader!../../../library/components/accordion/html/outline-icon.html")

const OutlineElevatedAccordionJsx = require("!!raw-loader!../../../library/components/accordion/react/elevated-icon.jsx")
const OutlineElevatedAccordionHTML = require("!!raw-loader!../../../library/components/accordion/html/elevated-icon.html")

const AccordionsPage = () => {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "AccordionBasic",
      title: "Basic accordion",
      active_tab: 1,
    },
    {
      component_name: "AccordionWithIcons",
      title: "Basic with icons",
      active_tab: 1,
    },
    {
      component_name: "OutlineAccordion",
      title: "Outline",
      active_tab: 1,
    },
    {
      component_name: "OutlineAccordionWithIcons",
      title: "Outline with icons",
      active_tab: 1,
    },
    {
      component_name: "AccordionElevated",
      title: "Elevated",
      active_tab: 1,
    },
    {
      component_name: "OutlineElevatedAccordion",
      title: "Elevated with icons",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Accordions - WindUI Component Library"
        ogtitle="Tailwind CSS Accordions - WindUI Component Library"
        description="The accordion component is a collection of vertically collapsing header and body elements that can be used to show & hide information. Built with Tailwind CSS."
        ogdescription="The accordion component is a collection of vertically collapsing header and body elements that can be used to show & hide information. Built with Tailwind CSS."
        url="components/accordions/"
        ogimage={ogImage}
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Accordions, Accordions, Accordion Components, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Accordions</h1>
          <p>
            The accordion component is a collection of vertically collapsing
            header and body elements that can be used to show and hide
            information.
          </p>

          <div className="flex w-full items-center justify-center rounded-xl bg-wuiSlate-50 p-8 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 md:p-16">
            <div className="w-full max-w-4xl" aria-multiselectable="false">
              <AccordionHeroComponent />
            </div>
          </div>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          {/* Basic Accordion Section */}
          <h3 id="AccordionBasic">Basic Accordion</h3>

          <PreviewBlock
            id="AccordionBasic"
            HtmlComponent={AccordionBasicHTML.default}
            JsxComponent={AccordionBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 md:p-16">
              <AccordionBasicPreview
                AccordionBasic={
                  activeTabs[0].active_tab === 1
                    ? AccordionBasicHTML.default
                    : AccordionBasicJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Accordion with Icons Section */}
          <h3 id="AccordionWithIcons">Basic Accordion with Icons</h3>

          <PreviewBlock
            id="AccordionWithIcons"
            HtmlComponent={AccordionWithIconsHTML.default}
            JsxComponent={AccordionWithIconsJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 md:p-16">
              <AccordionWithIconPreview
                AccordionWithIcons={
                  activeTabs[1].active_tab === 1
                    ? AccordionWithIconsHTML.default
                    : AccordionWithIconsJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Outline Basic Accordion Section */}
          <h3 id="OutlineAccordion">Outline Accordion</h3>

          <PreviewBlock
            id="OutlineAccordion"
            HtmlComponent={OutlineAccordionHTML.default}
            JsxComponent={OutlineAccordionJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-wuiSlate-50 p-8 md:p-16">
              <OutlineAccordionPreview
                OutlineAccordion={
                  activeTabs[2].active_tab === 1
                    ? OutlineAccordionHTML.default
                    : OutlineAccordionJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Outline Accordion with Icons Section */}
          <h3 id="OutlineAccordionWithIcons">Outline Accordion with Icons</h3>

          <PreviewBlock
            id="OutlineAccordionWithIcons"
            HtmlComponent={OutlineAccordionWithIconsHTML.default}
            JsxComponent={OutlineAccordionWithIconsJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-wuiSlate-50 p-8 md:p-16">
              <OutlineAccordionWithIconPreview
                OutlineAccordionWithIcons={
                  activeTabs[3].active_tab === 1
                    ? OutlineAccordionWithIconsHTML.default
                    : OutlineAccordionWithIconsJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Elevated Accordion Section */}
          <h3 id="AccordionElevated">Elevated Accordion</h3>

          <PreviewBlock
            id="AccordionElevated"
            HtmlComponent={AccordionElevatedHTML.default}
            JsxComponent={AccordionElevatedJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-wuiSlate-50 p-8 md:p-16">
              <AccordionElevatedPreview
                AccordionElevated={
                  activeTabs[4].active_tab === 1
                    ? AccordionElevatedHTML.default
                    : AccordionElevatedJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Outline Elevated Accordion Section */}
          <h3 id="OutlineElevatedAccordion">Elevated Accordion with Icons</h3>

          <PreviewBlock
            id="OutlineElevatedAccordion"
            HtmlComponent={OutlineElevatedAccordionHTML.default}
            JsxComponent={OutlineElevatedAccordionJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-wuiSlate-50 p-8 md:p-16">
              <ElevatedIconAccordionPreview
                OutlineElevatedAccordion={
                  activeTabs[5].active_tab === 1
                    ? OutlineElevatedAccordionHTML.default
                    : OutlineElevatedAccordionJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            The accordion component is used to deliver large amounts of content
            in a small space through progressive disclosure.{" "}
          </p>
          <p>
            To implement the accordion the native <code>&lt;summary&gt;</code>{" "}
            <a
              href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/summary"
              rel="external"
              target="_blank"
            >
              HTML element
            </a>{" "}
            is used. Contents can be any heading content, plain text, or HTML
            that can be used within a paragraph.
          </p>
          <p>
            A <code>&lt;summary&gt;</code> element may only be used as the first
            child of a <code>&lt;details&gt;</code> element. When the user
            clicks on the summary, the parent <code>&lt;details&gt;</code>{" "}
            element is toggled open or closed, and then a toggle event is sent
            to the <code>&lt;details&gt;</code>
            element, which can be used to let you know when this state change
            occurs.
          </p>
        </Section>
        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>
            Each accordion is wrapped within a <code>&lt;details&gt;</code>{" "}
            element with a 1rem (16px) internal padding and can consist of:{" "}
          </p>
          <ul>
            <li>
              <strong>Summary: </strong> the title of the component. Font size
              of 1rem (16px) and 500 font-weight. It can also have an icon of
              1.5rem (24px) in width and height.
            </li>
            <li>
              <strong>Content:</strong> Any HTML content.
            </li>
          </ul>
        </Section>
        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <p>
            Since accordions are built with the native{" "}
            <code>&lt;details&gt;</code> element no additional accessibility
            roles are required.
          </p>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}

export default AccordionsPage
